<template>
    <div>
        <a-row v-for="(scope, key) in dataTableList" :key="key">
            <div style="position:relative;height: 0;width:100%">
                <div v-if="scope.showtip" class="tip">我们将在确认到账后24小时内为您开通权益</div>
            </div>
            <a-col :span="24" class="list-header"
                style="line-height:46px;background: #F4F6F6;border: 1px solid #EBF0F2;padding-left: 1%;">
                <span style="padding-right:40px;">下单时间： {{scope.create_at}}</span>
                <span @click="onCopyOrder(scope.order_no)" style="padding-left:1%;cursor:pointer;">订单编号：
                    {{scope.order_no}} <icon-font type="iconcopy"></icon-font></span>
                <span style="color:#0079F9;float: right;margin-right: 10px;cursor: pointer;font-weight: 400;" @click="onInfo(scope.order_id)">查看详情  </span>
            </a-col>
            <a-col :span="24"
                style="height:130px;border: 1px solid #EBF0F2;display:flex;align-items:center;padding-left: 1%;background-color: white;margin-bottom: 28px;">
                <a-col :span="4">
                    <!-- <img src="@/assets/images/web/music/vip_package.svg"> -->
                    <div class="name">超值套餐</div>
                </a-col>
                <a-col :span="4">
                    <span>{{scope.goods_name}}</span>
                </a-col>
                <!-- <a-col :span="4">
                <span>授权次数 {{scope.authed_num}}</span>
                <span> / {{scope.auth_num}}</span>
            </a-col> -->
                <!-- <a-col :span="4">
                <span>下载次数 {{scope.downloaded_num}}</span>
                <span> / {{scope.download_num}}</span>
            </a-col> -->
                <a-col :span="6" style="text-align:left;">
                    <a-col :span="24">
                        <span>授权数量: {{scope.auth_num}}首</span>
                    </a-col>
                    <a-col :span="24">
                        <span>授权用途: {{scope.use_mode}}</span>
                    </a-col>
                    <a-col :span="24">
                        <span>授权地域: {{scope.auth_area | toArea}}</span>
                    </a-col>
                    <a-col :span="24">
                        <span>授权期限: {{scope.auth_time | toAuthPeriod}}</span>
                    </a-col>
                    <!-- <a-col :span="24">
                    <span>到期时间: {{scope.expire_date}}</span>
                </a-col> -->
                </a-col>
                <!-- <td v-if="key == 0" :rowspan="scope.detail.length" style="width:15%;text-align:center;"> -->
                <!-- <a-row> -->
                <a-col :span="5">
                    <a-col :span="24">
                        <span v-if="scope.status==0">订单待支付</span>
                        <span v-if="scope.status==1">订单已取消</span>
                        <span v-if="scope.status==2">订单已取消</span>
                        <span v-if="scope.status==3">订单支付中</span>
                        <span v-if="scope.status==4">订单已支付</span>
                    </a-col>
                    <a-col :span="24">
                        <span>总额：</span>
                        <span style="color:red;">¥{{scope.total_amount}}.00</span>
                    </a-col>
                </a-col>

                <!-- </a-row> -->
                <!-- </td> -->
                <!-- <td v-if="key == 0" :rowspan="scope.detail.length" style="width:15%;text-align:center;"> -->
                <a-col :span="4">
                    <a-col :span="24">
                        <div v-if="scope.status==0" class="order1" @click="onOrder(scope)">立即支付</div>
                    </a-col>
                    

                    <a-col :span="24">

                        <div v-if="scope.status==3" class="order2" @mouseenter="mouseEnter(1,key)"
                            @mouseout="mouseEnter(0,key)">待处理</div>
                    </a-col>
                    <a-col :span="24" style="text-align: center;">
                        <span v-if="scope.status==0||scope.status==3"  @click="onCancelOrder(scope)" style="cursor: pointer;font-weight: 400;">取消订单</span>
                    </a-col>
                    <a-col :span="24">
                        <div v-if="scope.status==4 && scope.is_invoiced==0" class="order3">申请发票</div>
                    </a-col>
                    <a-col :span="24" style="text-align: center;">
                        <span v-if="scope.status==4 && scope.is_invoiced==1" style="color:#0079F9;">查看发票</span>
                    </a-col>
                    <a-col :span="24" style="text-align: center;">
                        <span v-if="scope.status==2||scope.status==1" style="color:#999999;">已关闭</span>
                    </a-col>
                    <a-col :span="24" style="text-align: center;">
                        <span v-if="scope.status==4" style="color:#999999;">已完成</span>
                    </a-col>
                </a-col>
                <!-- </td> -->
            </a-col>
        </a-row>
        <a-row v-if="dataTableList.length == 0" style="text-align:center;padding-top:24px;">
            <img src="@/assets/images/empty.png" style="width:76px;" />
            <br /><span style="color:rgba(0, 0, 0, 0.25);">暂无数据</span>
        </a-row>
    </div>
</template>

<script>
    import { copyStr } from '@/utils/function'
    export default {
        data() {
            return {
                dataTableList: [],
                // showtip:[],
                stype : this.params['type'],
            }
        },

        props: {
            params: Object,
        },
        components: {
        },
        watch: {
            params(newVal, oldVal) {
                console.log(newVal);
                this.stype = newVal['type'];
                this.getData();
            }
        },
        created: function () {
            this.getData();
        },

        methods: {
            onCopyOrder(str) {
                try {
                    copyStr(str);
                    this.$message.success(this.$t('result.copySuccess'));
                } catch (error) {
                    console.error(error);
                }
            },
            getData() {
                let params = { "type": this.stype, "page": 1 };
                this.$axios.Package_orderlist(params).then(res => {
                    const data = res.data;
                    if (data && data.code == 0) {
                        let list = data.data ? data.data.list : [];
                        this.dataTableList = list;
                        console.log(this.dataTableList);
                        list.forEach(e => {
                            e.showtip = 0;
                        });
                    }
                })
            },
            mouseEnter(f, key) {
                var d = this.dataTableList[key]
                d.showtip = f;
                this.$set(this.dataTableList, key, d);
            },
            onInfo(_id) {
                this.$router.push({path:'/order_v2/'+_id+"/3"});
            },
            onCancelOrder(scope){
                let that = this;

                this.$confirm({
                    width: '480px',
                    title: this.$t('template.confirmCancel', {
                        name: this.$t('order.name')
                    }),
                    onOk() {
                        return new Promise((resolve, reject) => {
                            that.confirmVipFunc(scope, resolve, reject);
                        });
                    }
                });
            },
            onOrder(scope){
                this.$router.push({path:'/paypackage/'+scope.order_id});
            },
            confirmVipFunc(scope, next, reject) {
                let params = {
                    order_id: scope.order_id || ''
                }

                this.$axios.Package_orderCancel(params).then(res => {
                    const data = res.data;

                    if (data && data.code == 0) {
                        this.$message.success(this.$t('template.canceledTip', {
                            name: this.$t('order.name')
                        }));
                        next();
                        // this.reloadloadData = true;
                        // this.$emit('loadlist')
                        this.getData();
                    } else {
                        reject()
                    }
                })
            },
        },

        
        
    }
</script>

<style lang="scss" scoped>
    .name {
        width: 82px;
        height: 34px;
        background: linear-gradient(132deg, #73A2BE 0%, #578AA9 100%);
        border-radius: 6px;
        line-height: 34px;
        margin-left: 30px;
        color: white;
        text-align: center;
    }

    .tip {
        position: absolute;
        font-size: 12px;
        width: 260px;
        height: 39px;
        line-height: 39px;
        text-align: center;
        top: 45px;
        right: -35px;
        background: #000000;
        opacity: 0.55;
        color: #FFFFFF;
        border-radius: 4px;
        z-index: 99;
    }

    .order1 {
        width: 80px;
        height: 32px;
        background: linear-gradient(180deg, #2B9EFF 0%, #3F96E1 100%);
        border-radius: 4px;
        color: #FFFFFF;
        line-height: 32px;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
        font-weight: 400;
    }

    .order2 {
        width: 80px;
        height: 32px;
        background: #AEAEAE;
        border-radius: 4px;
        color: #FFFFFF;
        line-height: 32px;
        margin: 0 auto;
        text-align: center;
    }

    .order3 {
        width: 80px;
        height: 32px;
        background: linear-gradient(180deg, #2B9EFF 0%, #3F96E1 100%);
        border-radius: 4px;
        color: #FFFFFF;
        line-height: 32px;
        margin: 0 auto;
        text-align: center;
    }
</style>